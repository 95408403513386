<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置： <router-link :to="{path:'/mine'}">我的预约</router-link> > 预约详情</div>
			<div class="content" v-loading="loading">
				<div>
					<div class="info flex">
						<div class="left">
							<div class="page">
								<p class="left_title flex_a"><img src="@/assets/images/dian.png"/>参观日期</p>
								<p>预约日期：{{detail.bookingDate}}</p>
								<p>预约时段：{{detail.bookingPeriodStr}}</p>
							</div>
							<div class="page">
								<p class="left_title flex_a"><img src="@/assets/images/dian.png"/>预约信息</p>
								<p>预约类型：{{detail.typeStr}}</p>
								<p v-if="detail.type == 2">参观单位：{{detail.visitingUnit}}</p>
								<p>{{detail.type == 1 ? '领队' :(detail.type == 2 ? '联系人': '')}}姓名：{{detail.name}}</p>
								<p>{{detail.type == 2 ? '联系人' : ''}}身份证号：{{detail.idNo}}</p>
								<p>{{detail.type == 2 ? '联系人' : ''}}手机号码：{{detail.phone}}</p>
								<p class="flex" v-if="detail.type == 2 && detail.officialLetterName">公函/介绍信：
									<el-image
										style="width: 44px; height: 24px"
										:src="url"
										:preview-src-list="detail.officialLetterName.split(',')">
									</el-image>

									<!-- <el-popover
										placement="right"
										width="600"
										trigger="click">
										<img class="coverimg-big" :src="detail.officialLetter"/>
										<span slot="reference">公函</span>
									</el-popover> -->
								</p>
								<p v-if="detail.type == 1 || detail.type == 2">团队参观人数：{{detail.teamNums}}</p>
								<p v-if="detail.type == 1 || detail.type == 2">备注：{{detail.remark}}</p>
							</div>
							<div class="page">
								<p class="left_title flex_a"><img src="@/assets/images/dian.png"/>预约状态</p>
								<p>{{detail.auditStatusStr}}</p>
							</div>
							<div class="page" v-if="detail.type == 0 && detail.auditStatus == 1">
								<p class="left_title flex_a"><img src="@/assets/images/dian.png"/>预约码</p>
								<p><img class='ewm' :src="detail.bookingCode"/></p>
							</div>
						</div>
<!--						<div class="right">-->
<!--							<p class="left_title flex_a"><img src="@/assets/images/dian.png"/>讲解信息</p>-->
<!--							<div class="all">-->
<!--								<p>{{detail.explainProjectStr}}</p>-->
<!--								&lt;!&ndash; <p>单价：{{detail.type == 2 ? '免费' : (detail.explainProject == 0 ? '免费' : (detail.explainProject == 1 ? '￥200' : '￥100'))}}</p> &ndash;&gt;-->
<!--							</div>-->
<!--							&lt;!&ndash; <div class="payment">需支付：<label v-if="detail">{{detail.type == 2 ? '￥0' : (detail.explainProject == 0 ? '￥0' : '￥'+detail.payMoney)}}</label></div> &ndash;&gt;-->
<!--						</div>-->
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				loading: false,
				id: '',
				detail: '',
				url: require('../../assets/images/gonghan.png')

			}
		},
		created() {
			this.id = this.$route.query.id

		},
		mounted() {
			this.getDetail()
		},
		methods: {
			getDetail() {
				this.loading = true
				let formData = new FormData()
					formData.append('id', this.id)
				this.$http({
					url: `/bookingRecordApi/queryBookingRecordById`,
					method: "POST",
					data: formData
				}).then((res) => {
					this.loading = false
					if(res.state == 10200){
						this.detail = res.data
					}

				});
			},

		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		background: #FFF;
		padding-bottom: 30px;
		.content{
			min-height: 453px;
			width: 950px;
			background: url('../../assets/images/wodeyuyuekuang.png') no-repeat;
			background-size: 100% 100%;
			margin: 0 auto;
			margin-top: 30px;
			padding: 40px 60px;
			font-size: 16px;
			color: #595E63;
			display: flex;
			align-items: center;
			justify-content: center;
			.left_title{
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 15px;
				img{
					margin-right: 10px;
				}
			}
			.left{
				.page{
					margin-bottom: 30px;
					span{
						background: #3485B9;
						color: #FFF;
						font-size: 12px;
						border-radius: 5px;
						padding: 5px 10px;
						cursor: pointer;
					}
					.left_title{
						padding: 0;
					}
					p{
						line-height: 27px;
						padding-left: 18px;
						.ewm{
							width: 99px;
							height: 99px;
						}
					}
				}
			}
			.right{
				margin-left: 100px;
				.all{
					width: 380px;
					border: 1px dashed #DCDCDC;
					padding: 15px 20px;
					p{
						&:nth-of-type(1){
							font-size: 24px;
							margin-bottom: 10px;
						}
					}
				}
				.payment{
					text-align: center;
					background: #F1F1F1;
					color: #595E63;
					margin-top: 20px;
					padding: 10px 0;
					label{
						color: #A5343C;
					}
				}

			}

		}

	}
}

</style>
